export const GET_PROJECTS = "GET_PROJECTS";
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS";
export const GET_PROJECTS_FAILED = "GET_PROJECTS_FAILED";

export const GET_PROJECT_DETAIL = "GET_PROJECT_DETAIL";
export const GET_PROJECT_DETAIL_SUCCESS = "GET_PROJECT_DETAIL_SUCCESS";
export const GET_PROJECT_DETAIL_FAILED = "GET_PROJECT_DETAIL_FAILED";

export const GET_STAFFS = "GET_STAFFS";
export const GET_STAFFS_SUCCESS = "GET_STAFFS_SUCCESS";
export const GET_STAFFS_FAILED = "GET_STAFFS_FAILED";

export const GET_ALUMNI_STUDENTS = "GET_ALUMNI_STUDENTS";
export const GET_ALUMNI_STUDENTS_SUCCESS = "GET_ALUMNI_STUDENTS_SUCCESS";
export const GET_ALUMNI_STUDENTS_FAILED = "GET_ALUMNI_STUDENTS_FAILED";

export const GET_GALLERY = "GET_GALLERY";
export const GET_GALLERY_SUCCESS = "GET_GALLERY_SUCCESS";
export const GET_GALLERY_FAILED = "GET_GALLERY_FAILED";

export const GET_GALLERY_DETAIL = "GET_GALLERY_DETAIL";
export const GET_GALLERY_DETAIL_SUCCESS = "GET_GALLERY_DETAIL_SUCCESS";
export const GET_GALLERY_DETAIL_FAILED = "GET_GALLERY_DETAIL_FAILED";

export const CLEAN_UP = "CLEAN_UP";
export const CLEAN_UP_PROJECTS = "CLEAN_UP_PROJECTS";
export const CLEAN_UP_PROJECT_DETAIL = "CLEAN_UP_PROJECT_DETAIL";
export const CLEAN_UP_GALLERY = "CLEAN_UP_GALLERY";
export const CLEAN_UP_GALLERY_DETAIL = "CLEAN_UP_GALLERY_DETAIL";
export const CLEAN_UP_ALUMNI = "CLEAN_UP_ALUMNI";
export const CLEAN_UP_STAFF = "CLEAN_UP_STAFF";




